import React from "react";

export default function Maintenance() {
  return (
    <div className="container text-center">
      <img
        src={require("assets/images/maintenance.jpg")}
        className="margin-md-top margin-md-bottom"
        alt="website undermaintenance"
      />
    </div>
  );
}